.portada {
    height: 70vh;
    width: 100%;
    background-image: url("../../Assets/portada.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: -1;
}
.bold {
  font-weight: bold;
  text-align: left;
}

.contenedor {
  width: 800px;
  margin: auto;
  position: relative;  
  
}

p {
  font-size: 1em;
  text-align: justify;
  /*   line-height: 2; */
}
.text-align-justify{
  text-align: justify;
}
ul{
  padding-bottom: 2.7rem;
  text-align: justify;
}
ul li{
    margin:  1rem 1rem 0rem 1rem
}
.subt{
  color:#00b8ff;
  font-size: 1.2rem;
}
.ml1{
  margin-left: 1rem;
}
/* h1,
h2,
h3 {
  font-size: 24px;
  font-weight: 700;
} */
.img_planta {
  display: block;
  height: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
/*#intro,
		#our-history,
		#vision-mision,
		#partners {
		  min-height: 600px;
		}*/
.pt1{
  padding-top:1rem;
}
.pt-100 {
  padding-top: 100px;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.title h2{
  padding: 1.7rem 0;
  background: var(--gradiente);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 700;
  /* white-space: nowrap; */
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 2.5rem;
}

@media screen and  (min-width: 349px) and (max-width: 575px) {
  .title h2{
      font-size: 2rem;
  }
  p {
    font-size: 1rem;
}
} 