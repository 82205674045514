.contacto_fondo{
    background-image: url("../../Assets/beneficios.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
    padding: 5rem 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contacto_total {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contacto_izquierda {
    background-color: var(--grisClaro);
    color: var(--negro);
    padding: 3rem 4rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contacto_izquierda p{
    line-height: 2rem;
}

.contacto_derecha {
    background-color: var(--gris);
    font-size: 0.9rem;
    padding: 2rem;
    color: white;
}
@media screen and  (min-width: 349px) and (max-width: 575px) {

.contacto_izquierda{
    padding: 1rem 1.2rem;
}
.contacto_total {
    width: 100%;
}
.contacto_fondo{
    padding: 2rem 2rem;
}
.contacto_izquierda p{
    font-size: 0.85rem;
    
}
.contacto_derecha {
    font-size: 0.8rem;
    letter-spacing: 1px;
    padding: 0.5rem;
}
.boton span {
    font-size: 0.8rem;
    padding: 10px 10px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: 300ms;
}
}