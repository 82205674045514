.social_bar{
    position: fixed;
    right: 0;
    top: 35%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 100;
}

.icon{
    padding: .7rem;
    display: flex;
    transition: all .5s;
}

.icon-whatsapp{
    background-color: #25D366;
}

.icon-facebook{
    background-color: #2E406E;
}

.icon-instagram{
    background-color: #ac2bac;
}

.icon-youtube{
    background-color: #E83028;
}

.icon:first-child{
    border-radius: 1rem 0 0 0;
}

.icon:last-child{
    border-radius: 0 0 0 1rem;
}

.icon:hover{
    padding-right: 3rem;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.42);
}