@import url('https://fonts.googleapis.com/css?family=Pontano+Sans');

$white: #fff;
$black: #333;
$lightergray: #d5d5d5;
$lightgray: #f0f3f1;
$gray: #e3dddd;
$text: #888;
$button: #3e3e3f;
$button-hover: #565657;

* {
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box;
  }
}

body {
  font-family: 'Pontano Sans', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  color: $text;
  background: $white;
  text-rendering: optimizeLegibility;
}

.tituloFila p {
  text-transform: lowercase;
  &:first-letter {
  text-transform: uppercase;
  }
}


ul {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    margin: 0 1.75rem 0 0;
  }
}

a {
  color: $text;
  text-decoration: none;
  transition: all 0.2s ease;
  &:hover {
    color: $black;
  }
  &.active {
    color: $black;
  }
}

h1, h2, h3, h4 {
  color: $black;
  font-weight: normal;
  margin: 1.75rem 0 1rem 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.125rem;
  margin: 0;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
  margin: 1rem 0 0.5rem 0;
}

section {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.item {
  height: 100vh;
}

nav {
  display: block;
  li {
    font-size: 1.125rem;
    margin: 0;
  }
}

.flex-nav ul {
  position: absolute;
  z-index: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  display: none;
  width: 100%;
  left: 0;
  padding: 1rem;
  background: $white;
  text-align: center;
  &.active {
    display: flex;
  }
  li {
    margin: 0.5rem 0;
  }
}

.toggle-nav {
  display: flex;
  justify-content: flex-end;
  font-size: 1.125rem;
  line-height: 1.7;
  margin: 1rem 0;
  i {
    font-size: 1.5rem;
    line-height: 1.4;
    margin: 0 0 0 0.5rem;
  }
}

#highlight {
  color: $black;
  font-size: 1.125rem;
  text-transform: uppercase;
}

.price {
  margin: 0;
}

.breadcrumb-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 1rem 0 0 0;
  list-style: none;
  li {
    font-size: 0.85rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
  }
}

.breadcrumb-item {
  &.active {
    color: $black;
  }
  + .breadcrumb-item {
    &::before {
      content: '/';
      display: inline-block;
      padding: 0 0.5rem;
      color: $lightergray;
    }
  }
}

.description {
  border-top: 0.0625rem solid $gray;
  margin: 2rem 0;
  padding: 1rem 0 0 0;
}

.add-to-cart {
  position: relative;
  display: inline-block;
  background: $button;
  color: $white;
  border: none;
  border-radius: 0;
  padding: 1.25rem 2.5rem;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  transform: translateZ(0);
  transition: color 0.3s ease;
  letter-spacing: 0.0625rem;
  &:hover {
    &::before {
      transform: scaleX(1);
    }
  }
  &::before {
    position: absolute;
    content: '';
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $button-hover;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: transform 0.3s ease-out;
  }
}

.container {
  margin: auto;
  padding: 0 1rem;
  max-width: 75rem;
  width: 100%;
}

.grid {
  > [class*="column-"] {
    padding: 1rem;
  }
  &.menu, &.product {
    border-bottom: 0.0625rem solid $gray;
  }
  &.menu {
    > [class*="column-"] {
      padding: 0.5rem 1rem 0.5rem 1rem;
    }
  }
  &.product {
    padding: 0 0 1.5rem 0;
  }
  &.second-nav {
    > [class*="column-"] {
      padding: 0.5rem 1rem;
    }
  }
}

footer {
  padding: 1rem 0;
  text-align: center;
}

.product-image {
  display: block;
}

.image-list {
  li {
    margin: 2px;
  }
  img {
    height: 20rem;
    width: 100%;
    transition: opacity 0.3s ease;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}


@media  (min-width: 768px) {
  .product-image img, .image-list img {
    width: 100%;
  }

  .product-image {
    img {
      height: 72vh;
      &.active {
        display: block;
        margin: 0 0 0.75rem 0;
      }
    }
  }
  
  .image-list {
    display: flex;
    overflow: hidden;
    li {
      margin: 0 0.75rem 0 0;
      flex-basis: 100%;
      &:nth-child(3) {
        margin: 1;
      }
    }
    img {
      height: 10rem;
      width: 100%;
      transition: opacity 0.3s ease;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  nav ul {
    justify-content: flex-end;
  }

  .toggle-nav {
    display: none;
  }

  .flex-nav {
    display: block;
    ul {
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: flex-end;
      li {
        font-size: 1.125rem;
        margin: 0 1.5rem 0 0;
        &:nth-child(4) {
          margin: 0;
        }
      }
    }
  }
}

@keyframes fadeImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}