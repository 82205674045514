.carrusel-left{
    flex: 20%;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
}

.carrusel-left img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 0%;
    cursor: pointer;
    opacity: 0.5;
    border: 0.0625rem solid rgba(0,0,0,.2);
    padding: 0.5rem;
    margin: 0.625rem 0.625rem 0.9375rem;
}

.carrusel-left img:hover{
    opacity: 1;
    transition: all .5s ease;
}

.carrusel-right{
    flex: 80%;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
}

.carrusel-right img{
    width: 100%;
    height: 100%;
}