/* footer{
    background-color: #000000;
}

.productos a{
    text-decoration: none;
}

.contactanos a{
    text-decoration: none;
}

.facebook, .instagram, .twitter{
    width: 2.3125rem;
    height: 2.3125rem;
    border-radius: 50%;
    padding: 0.3rem;
    position: relative;
    font-weight: 400;
}

.facebook{
    background-color: #3b5998 !important;
}

.instagram{
    background-color: #ac2bac !important;
}

.twitter{
    background-color: #55acee !important;
}

.mediosPago{
    width: 2.5rem;
    height: 1.5rem;
    margin: 0 5px 5px 0;
    vertical-align: middle;
    border: 0;
}

.copyright{
    background-color: #333333;
} */
p{
    font-size: 0.85rem;
}
footer{
    background: var(--negro);
    width: 100%;
}

.footer_a{
    padding-right: 0;
    padding-left: 0;
    display: flex;
    justify-content: center;
}

.footer_a a{
    text-decoration: none;
}

.footer_texto{
    font-weight: bold;
    color: var(--turquesa);
    letter-spacing: .3rem;
}

.footer_icono_fondo{
    background-color: white;
    border-radius: 50%;
    width: 5.25rem;
    height: 5.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 90%;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
    -webkit-box-shadow: 0px 1px 24px 1px rgba(20,143,143,1);
    -moz-box-shadow: 0px 1px 24px 1px rgba(20,143,143,1);
    box-shadow: 0px 1px 24px 1px rgba(20,143,143,1);
    transform: scale(1);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
}

.footer_icono_fondo:hover{
    transform: scale(1.1);
}

.footer_icono_texto{
    color: white;
    margin-top: 3.5rem;
}
.columna_footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media (max-width:768px){
    .columna_footer{
    }
    .footer_icono_fondo{
        position: relative;
        bottom: -1rem;
        background-color: rgb(255, 255, 255);
        border-radius: 50%;
        /* width: 4rem;
        height: 4rem; */
        -webkit-box-shadow: 0px 1px 24px 1px rgba(20,143,143,1);
        -moz-box-shadow: 0px 1px 24px 1px rgba(20,143,143,1);
        box-shadow: 0px 1px 24px 1px rgba(20,143,143,1);
        transform: scale(1);
        transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
        cursor: pointer;
    }
  }