.dropdown-item {
    padding: 0.25rem 0.5rem;
}

.navbar {
    background-color: #f0f0f0 !important;
    color: #29949c;
    padding: 0.5rem 3rem;
    /* height: 6rem; */
}

.navbar-brand,
.nav-link,
.dropdown-item {
    color: #29949c !important;
}

.nav-link:hover,
.dropdown-item:hover {
    color: #676363 !important;
}

.dropdown-menu {
    background-color:#f0f0f0 ;
}

.dropdown-divider {
    background-color: #dcf7f9;
}

.dropdown-toggle-right .dropdown-toggle::after {
    margin-left: 0.5em;
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    vertical-align: 0.15em;
}

.dropdown-submenu-right .dropdown-menu {
    left: 100%;
    right: auto;
    top: 0;
}