/* .category_container{
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    height: 65vh;
}

.category_container2{
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,-4);
    cursor: pointer;
} */

/*.category_container2 img{
    background-repeat: no-repeat;
    background-position: top;
    object-fit: cover;
    position: absolute;
}

.category_text{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.4);
    opacity: 0;
    transition: 0.5s;
    color: white;
    opacity: .9;
}

.category_text:hover{
    opacity: 0;
}

.category_text h4{
    position: absolute;
    font-size: 2rem;
} */


.grid {
	/* position: relative;
	margin: 0 auto;
	padding: 1em 0 4em;
	list-style: none;
	text-align: center; */
	position: relative;
	margin: 0 auto;
    overflow: hidden;
    justify-content: center;
	list-style: none;
	width: 100%;
	/* height: 100%; */
	object-fit: cover;
	/* max-width: 1000px; */
}

/* Common style */
.grid figure {
	position: relative;
	overflow: hidden;
	margin: 0.75rem 1%;
	min-width: 320px;
	max-width: 480px;
	max-height: 360px;
	width: 100%; /*Esto es para modificar el ancho*/
	background: #7ac8e4;
	text-align: center;
	cursor: pointer;	
	display: flex;
    align-items: inherit;
    justify-content: center;
}

.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

.grid figure figcaption {
	padding: 2rem;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25rem;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}

/* .grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
} */

/* Anchor will cover the whole item by default */
/* .grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
} */

.grid figure h4 {
	word-spacing: .3rem;
	/* font-weight: 300; */
	margin: 0;
}

.grid figure h4 span {
	font-weight: 800;
	color:#285262;
}

/* Individual effects */

/*---------------*/
/***** Lily *****/
/*---------------*/

figure.effect-lily img {
	max-width: none;
	/* width: -webkit-calc(100% + 50px);
	width: calc(100% + 50px); */
	width: 100%;
	opacity: 0.7;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(-40px,0, 0);
	transform: scale(1.1);
}

figure.effect-lily figcaption {
	text-align: center;
}

figure.effect-lily figcaption > div {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 2em;
	width: 100%;
	height: 35%;
}

figure.effect-lily h4 {
	-webkit-transform: translate3d(0,40px,0);
	transform: translate3d(0,40px,0);
	background: #f8f9fa;
}

figure.effect-lily h4 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}

figure.effect-lily:hover img {
	opacity: 1;
}

figure.effect-lily:hover img,
figure.effect-lily:hover h4 {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

@media screen and  (min-width: 349px) and (max-width: 575px) {
    .gallery_container h4{
        font-size: 1.2rem;
    }
} 